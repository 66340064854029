.markdown {
    h1 {
        font-size: 2.0em;
        border-bottom: 1px solid #888;
        font-weight: bold;
    }

    h2 {
        font-size: 1.8em;
        font-weight: bold;
    }

    h3 {
        font-size: 1.5em;
        font-weight: bold;
    }

    h4 {
        font-size: 1.25em;
        font-weight: bold;
    }

    h5 {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    del {
        text-decoration: line-through;
    }

    strong {
        font-weight: bold;
    }

    a {
        color: #0000EE;

        &:hover {
            text-decoration: underline;
        }
    }

    abbr {
        border-bottom: 1px dotted;
    }

    code {
        font-family: 'Courier New', Courier, monospace;
        padding: 0 0.25em;
    }

    pre code {
        display: block;
        padding: .5em;
    }

    p {
        margin: 1em 0;
    }

    blockquote {
        margin: 1em 0em;
        padding-left: 1.5em;
        border-left: 5px solid;
    }

    hr {
        margin: .5em auto;
        border-style: inset;
        border-width: 1px;
    }

    dl {
        margin: 1em 0;
    }

    dt {
        font-weight: bold;
    }

    dd {
        margin-left: 3em;
    }

    table {
        width: 100%;

        thead {
            font-weight: bold;

            th {
                border-bottom: 1px solid;
                border-right: 1px solid;

                &:last-child {
                    border-right: 0px;
                }
            }
        }

        tr {
            td {
                border-bottom: 1px solid;
                border-right: 1px solid;

                &:last-child {
                    border-right: 0px;
                }
            }

            &:last-child {
                td {
                    border-bottom: 0px;
                }
            }
        }
    }

    ol {
        margin: 1em 0;
        padding-left: 40px;
        list-style-type: decimal;

        li {
            display: list-item;
            list-style-type: decimal;
        }

        ol, ul {
            margin: 0;
        }
    }

    ul {
        margin: 1em 0;
        padding-left: 40px;
        list-style-type: disc;

        li {
            display: list-item;
            list-style-type: disc;
        }

        ol, ul {
            margin: 0;
        }
    }

    img {
        max-width: 100%;
    }

    .markdown-id {
        position: absolute;
        right: .5em;
        top: .5em;
    }

    .youtube {
        max-width: 100%;
    }

    .contains-task-list {
        .task-list-item {
            input {
                display: inline;
            }
        }
    }
}
